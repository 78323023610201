import React from 'react'
import "../pages/regalo.css"
import "../pages/index.css"
import flores1 from "/src/images/Flores2.png"
import flores2 from "/src/images/Flores1.png"

import Navigation from "../components/navbar"

function RegaloPage() {
  return (
    <>
      <Navigation />
      <title>GABELLA - Regalos</title>
      <div className='regalo__container'>
        <img src={flores2} className="regalo__flower2" />
        <img src={flores1} className="regalo__flower1" />
        <h2 className='text__rose '>regalo</h2>
        <h3 className='regalo__text t-m'>Tu presencia es nuestro mejor regalo, toda muestra de cariño adicional es muy agradecida.</h3>
        <p className='t-sm m-0 '>Hemos apartado una mesa en:</p>
        <h3 className='regalo__text t-up text__rose t-m m-0'>Days Wonderstore</h3>
        <p className='t-m'>The Plaza Coco del Mar, PTY</p>
        <a href="https://www.instagram.com/dayswonderstore/" className='m-sm link'>Visit Store</a>
        <h3 className='regalo__text t-up text__rose t-m'>lluvia de sobres / cuenta bancaria</h3>
        <p className='t-m'>Antonella Maria Calvo Motta
          o Gabriel Octavio De Janon Rabat
          Banco General
          Cuenta de Ahorros
          <span className='t-bold'> No. 04-44-97-533722-0</span></p>

      </div>
      <div className='regalo__footer'>
        <p className='text__white t-m'>#GABELLA <spa className='text__orange  t-title'>07.01.23</spa> PANAMÁ</p>
      </div>
    </>
  )
}

export default RegaloPage
